/* oswald-regular*/
@font-face {
  font-family: "Oswald";

  font-style: normal;
  font-weight: 400;

  font-display: swap;

  src: local("Oswald Regular"),
    local("Oswald-Regular"),
    url("../fonts/oswaldregular.woff2") format("woff2"),
    url("../fonts/oswaldregular.woff") format("woff");
}
/* oswald-medium*/
@font-face {
  font-family: "Oswald";

  font-style: normal;
  font-weight: 500;

  font-display: swap;

  src: local("Oswald medium"),
    local("Oswald-Medium"),
    url("../fonts/oswaldmedium.woff2") format("woff2"),
    url("../fonts/oswaldmedium.woff") format("woff");
}

/* ptsans-regular*/
@font-face {
  font-family: "PtSans";

  font-style: normal;
  font-weight: 500;

  font-display: swap;

  src: local("PTSans-Regular"),
    url("../fonts/PTSans-Regular.woff") format("woff2");
}
