.preview {
  position: relative;
}
.preview__render {
  border-radius: 15px;
  width: $preview-rect-size-mobile;
  height: $preview-rect-size-mobile;
}

.preview__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
}
.preview__btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  background-size: 70%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;

  &--camera {

    margin-right: 20px;
    background-image: url("../img/icon-camera.svg");
  }
  &--reset {
    fill: brown;
    background-size: 100%;
    background-image: url("../img/icon-trash.svg");
  }
}

@media (min-width: $tablet-width) {
  .preview__render {
    border-radius: 30px;
    width: $preview-rect-size-tablet;
    height: $preview-rect-size-tablet;
  }
}

@media (min-width: $desktop-width) {
  .preview__render {
    border-radius: 45px;
    width: $preview-rect-size-desktop;
    height: $preview-rect-size-desktop;
  }
}