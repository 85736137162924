.page-main__flex {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}
.page-main__output {
  display: flex;
  flex-direction: column;
}

@media (min-width: $tablet-width) {
  .page-main__flex {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: $desktop-width) {
  .page-main__flex {
    padding-bottom: 40px;
    justify-content: space-around;
  }
}