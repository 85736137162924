.error-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 10;
  background-color: #ffbfbf;
  padding-bottom: 30px;
}

.error-screen__title {
  margin-top: 30px;
  margin-bottom: 10px;
}
.error-screen__reason {
  margin: 5px;
  margin-bottom: 15px;
}