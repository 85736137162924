/* oswald-regular*/
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Oswald Regular"), local("Oswald-Regular"), url("../fonts/oswaldregular.woff2") format("woff2"), url("../fonts/oswaldregular.woff") format("woff");
}
/* oswald-medium*/
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Oswald medium"), local("Oswald-Medium"), url("../fonts/oswaldmedium.woff2") format("woff2"), url("../fonts/oswaldmedium.woff") format("woff");
}
/* ptsans-regular*/
@font-face {
  font-family: "PtSans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("PTSans-Regular"), url("../fonts/PTSans-Regular.woff") format("woff2");
}
html {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #111111;
  background: #ffffff;
  text-transform: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.button {
  display: inline-block;
  padding: 10px;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  background-color: #35a9c0;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
}
.button:hover, .button:focus {
  background-color: #5eaa2f;
}
.button:active {
  color: rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .button {
    padding: 10px;
    font-size: 20px;
    line-height: 20px;
  }
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .container {
    width: 708px;
    padding-left: 0;
    padding-right: 0;
  }

  .container__narrow {
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1280px) {
  .container {
    width: 1220px;
    padding-left: 0;
    padding-right: 0;
  }

  .container__narrow {
    margin: 0 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.page-header {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #404040;
  background-color: aliceblue;
}

.page-header__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.page-header__logo {
  display: flex;
  cursor: pointer;
}
.page-header__logo:hover, .page-header__logo:focus {
  opacity: 0.8;
}
.page-header__logo:active {
  opacity: 0.6;
}

.page-header__logo-image {
  height: 40px;
}

@media (min-width: 768px) {
  .page-header {
    flex-direction: row;
  }

  .page-header__wrapper {
    flex-direction: row;
    align-items: center;
  }

  .page-header__logo {
    margin-top: 0;
    margin-bottom: 0;
  }

  .page-header__logo-image {
    width: 173px;
    height: 57px;
  }
}
@media (min-width: 1280px) {
  .page-header {
    margin-top: 0;
    margin-bottom: 0;
  }

  .page-header__logo-image {
    width: 202px;
  }
}
.page-main__flex {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}

.page-main__output {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .page-main__flex {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .page-main__flex {
    padding-bottom: 40px;
    justify-content: space-around;
  }
}
.camera {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.98);
}

.camera__video {
  position: relative;
  width: 280px;
  height: 280px;
  left: 50%;
  top: 185px;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: black;
  border-radius: 10px;
  object-fit: cover;
}

.camera__shot-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: 400px;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 50%;
  border: 3px solid rgba(128, 128, 128, 0.9);
  background-color: transparent;
}
.camera__shot-btn:before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: brown;
}

.camera__canvas {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .camera__video {
    width: 400px;
    height: 400px;
    top: 210px;
  }

  .camera__shot-btn {
    top: 490px;
  }
}
@media (min-width: 1280px) {
  .camera__video {
    width: 500px;
    height: 500px;
    top: 310px;
  }

  .camera__shot-btn {
    top: 670px;
  }
}
.preview {
  position: relative;
}

.preview__render {
  border-radius: 15px;
  width: 280px;
  height: 280px;
}

.preview__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.preview__btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  background-size: 70%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
}
.preview__btn--camera {
  margin-right: 20px;
  background-image: url("../img/icon-camera.svg");
}
.preview__btn--reset {
  fill: brown;
  background-size: 100%;
  background-image: url("../img/icon-trash.svg");
}

@media (min-width: 768px) {
  .preview__render {
    border-radius: 30px;
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 1280px) {
  .preview__render {
    border-radius: 45px;
    width: 400px;
    height: 400px;
  }
}
.output-desk {
  margin-left: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 100%;
}

.output-desk__title {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  margin: 0 auto 5px auto;
}

.output-desk__table {
  border: 2px solid grey;
  border-collapse: collapse;
  font-size: 10px;
  line-height: 12px;
  table-layout: fixed;
  width: 100%;
}

.output-desk__active-row {
  background-color: #b3fecc;
  font-size: 12px;
  line-height: 14px;
}

.output-desk__table td,
.output-desk__table th {
  padding: 2px 3px 3px 3px;
  text-align: center;
  border: 2px solid grey;
  word-wrap: wrap;
}

.output-desk__active-row td {
  padding-top: 4px;
  padding-bottom: 4px;
}

td.output-desk__predict-title {
  text-align: left;
}

@media (min-width: 768px) {
  .output-desk {
    margin: 0;
    max-width: 370px;
  }

  .output-desk__title {
    margin-bottom: 15px;
  }

  .output-desk__table {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (min-width: 1280px) {
  .output-desk {
    max-width: 600px;
  }

  .output-desk__table {
    font-size: 16px;
    line-height: 18px;
  }
}
.scan {
  position: relative;
  width: 280px;
  height: 280px;
  margin: 15px auto;
  background-image: url("../img/icon-scan.svg");
  background-color: #f0f8ff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.scan__shot-btn {
  font-size: 25px;
  position: absolute;
  font-family: PtSans;
  border: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  text-shadow: 1px 1px 2px grey;
}
.scan__shot-btn:hover {
  text-shadow: 2px 2px 1px grey;
}
.scan__shot-btn:active {
  text-shadow: -1px -1px 1px grey;
}

@media (min-width: 768px) {
  .scan {
    margin: 0;
    width: 300px;
    height: 300px;
    border-radius: 30px;
  }

  .scan__shot-btn {
    font-size: 30px;
  }
}
@media (min-width: 1280px) {
  .scan {
    width: 400px;
    height: 400px;
    border-radius: 45px;
  }
}
.page-footer {
  margin-top: auto;
  background-color: aliceblue;
  border-top: 2px solid #444444;
  padding: 20px 0;
}

.page-footer__wrapper {
  display: flex;
  flex-direction: column;
}

.page-footer__logo {
  padding-top: 0;
  padding-bottom: 28px;
  text-align: center;
}

.page-footer__logo-image:hover, .page-footer__logo-image:focus {
  opacity: 0.8;
}
.page-footer__logo-image:active {
  opacity: 0.6;
}

.page-footer__social {
  padding-top: 22px;
  padding-bottom: 17px;
  border-top: 1px solid #404040;
  border-bottom: 1px solid #404040;
}

.page-footer__copyright {
  padding-top: 22px;
  padding-bottom: 21px;
}

@media (min-width: 768px) {
  .page-footer {
    padding-top: 58px;
    padding-bottom: 58px;
  }

  .page-footer__wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer__logo {
    width: 170px;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .page-footer__social {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }

  .page-footer__copyright {
    width: 120px;
    padding: 0;
  }
}
.social__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 12px;
}

.social__item {
  display: flex;
  align-items: center;
  margin-left: 38px;
}
.social__item:first-child {
  margin-left: 0;
}

.social__link {
  display: block;
}

.social__svg {
  fill: #231f20;
}
.social__svg:hover, .social__svg:focus {
  fill: #68b738;
}
.social__svg:active {
  fill: #68b738;
  opacity: 0.3;
}
.social__svg--vk {
  width: 22px;
  height: 12px;
}
.social__svg--insta {
  width: 16px;
  height: 16px;
}
.social__svg--fb {
  width: 10px;
  height: 19px;
}

@media (min-width: 768px) {
  .social__list {
    margin-top: -1px;
    margin-right: 6px;
  }

  .social__item {
    margin-left: 28px;
  }

  .social__svg--vk {
    width: 30px;
    height: 16px;
  }
  .social__svg--insta {
    width: 23px;
    height: 23px;
  }
  .social__svg--fb {
    width: 14px;
    height: 28px;
  }

  .social__link--vkontakte {
    width: 30px;
    height: 16px;
  }

  .social__link--instagram {
    width: 23px;
    height: 23px;
  }

  .social__link--facebook {
    width: 14px;
    height: 28px;
  }
}
.copyright {
  margin-top: -2px;
  padding: 0;
  display: flex;
  justify-content: center;
}

.copyright__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 120px;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #444444;
}
.copyright__link:hover .copyright__svg, .copyright__link:focus .copyright__svg {
  fill: #68b738;
}
.copyright__link:active {
  opacity: 0.3;
}

.copyright__img {
  fill: #666666;
  flex-shrink: 0;
}

.error-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 10;
  background-color: #ffbfbf;
  padding-bottom: 30px;
}

.error-screen__title {
  margin-top: 30px;
  margin-bottom: 10px;
}

.error-screen__reason {
  margin: 5px;
  margin-bottom: 15px;
}