.social__list {
  display: flex;

  @include list-reset();

  justify-content: center;
  flex-wrap: wrap;
  padding-right: 12px;
}

.social__item {
  display: flex;
  align-items: center;
  margin-left: 38px;

  &:first-child {
    margin-left: 0;
  }
}

.social__link {
  display: block;
}

.social__svg {
  fill: #231f20;

  &:hover,
  &:focus {
    fill: #68b738;
  }

  &:active {
    fill: #68b738;
    opacity: 0.3;
  }

  &--vk {
    width: 22px;
    height: 12px;
  }

  &--insta {
    width: 16px;
    height: 16px;
  }

  &--fb {
    width: 10px;
    height: 19px;
  }
}

@media (min-width: $tablet-width) {
  .social__list {
    margin-top: -1px;
    margin-right: 6px;
  }

  .social__item {
    margin-left: 28px;
  }

  .social__svg {
    &--vk {
      width: 30px;
      height: 16px;
    }

    &--insta {
      width: 23px;
      height: 23px;
    }

    &--fb {
      width: 14px;
      height: 28px;
    }
  }

  .social__link--vkontakte {
    width: 30px;
    height: 16px;
  }

  .social__link--instagram {
    width: 23px;
    height: 23px;
  }

  .social__link--facebook {
    width: 14px;
    height: 28px;
  }
}
