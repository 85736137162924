.scan {
  position: relative;
  width: $scan-rect-size-mobile;
  height: $scan-rect-size-mobile;
  margin: 15px auto;
  background-image: url("../img/icon-scan.svg");
  background-color: #f0f8ff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.scan__shot-btn {
  font-size: 25px;
  position: absolute;
  font-family: PtSans;
  border: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;

  text-shadow: 1px 1px 2px grey;

  &:hover {
    text-shadow: 2px 2px 1px grey;
  }
  &:active {
    text-shadow: -1px -1px 1px grey;
  }
}

@media (min-width: $tablet-width) {
  .scan {
    margin: 0;
    width: $scan-rect-size-tablet;
    height: $scan-rect-size-tablet;
    border-radius: 30px;
  }

  .scan__shot-btn {
    font-size: 30px;
  }
}

@media (min-width: $desktop-width) {
  .scan {
    width: $scan-rect-size-desktop;
    height: $scan-rect-size-desktop;
    border-radius: 45px;
  }
}
