// @keyframes flashAnimation {
//   0% { opacity: 0.75; }
//   100% { opacity: 0; }
// }
.camera {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.98);
}

.camera__video {
  position: relative;
  width: $camera-rect-size-mobile;
  height: $camera-rect-size-mobile;
  left: 50%;
  top: 185px;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: black;
  border-radius: 10px;

  object-fit: cover;
}
.camera__shot-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: 400px;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 50%;
  border: 3px solid rgba(128, 128, 128, 0.9);
  background-color: transparent;

  &:before {
    position: absolute;
    content: '';
    width: 35px;
    height: 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: brown;
  }
}
.camera__canvas {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: $tablet-width) {
  .camera__video {
    width: $camera-rect-size-tablet;
    height: $camera-rect-size-tablet;
    top: 210px;
  }
  .camera__shot-btn {
    top: 490px;
  }

}
@media (min-width: $desktop-width) {
  .camera__video {
    width: $camera-rect-size-desktop;
    height: $camera-rect-size-desktop;
    top: 310px;
  }
  .camera__shot-btn {
    top: 670px;
  }
}